import React, { useEffect } from 'react';
import { CalendarViewModel } from '../ViewModel/viewModel';
import { CalendarActions } from '../Actions/actions';
import {
  WidgetProps,
  useEnvironment,
  useExperiments,
} from '@wix/yoshi-flow-editor';
import { CalendarStatus } from '../ViewModel/widgetViewModel/widgetViewModel';
import { useSettings } from '@wix/tpa-settings/react';
import { useWidgetAccessibilityAttributes } from '../Hooks/useWidgetAccessibilityAttributes/useWidgetAccessibilityAttributes';
import { CalendarActionsProvider } from '../Hooks/useCalendarActions';
import { CalendarFlowProvider, FlowElements, useFlow } from '../Hooks/useFlow';
import { useSettingsParams } from '../Hooks/useSettingsParams';
import { PresetProvider, usePreset } from '../Hooks/usePreset';
import Header from './Header/Header';
import RescheduledBookingDetails from './RescheduledBookingDetails/RescheduledBookingDetails';
import Dialog from './Dialog/Dialog';
import Toast from './Toast/Toast';
import Sidebar from './Sidebar/Sidebar';
import { BackButton } from './BackButton/BackButton';
import DailyAgendaWeeklyPickerLayout from './DailyLayout/DailyAgendaWeeklyPickerLayout/DailyAgendaWeeklyPickerLayout';
import WeeklyTimeSlotsLayout from './WeeklyLayout/WeeklyTimeSlotsLayout/WeeklyTimeSlotsLayout';
import WeeklyTimetableLayout from './WeeklyLayout/WeeklyTimetableLayout/WeeklyTimetableLayout';
import DailyTimeSlotsLayout from './DailyLayout/DailyTimeSlotsLayout/DailyTimeSlotsLayout';
import MonthlyDatePicker from './DailyLayout/DatePicker/MonthlyDatePicker/MonthlyDatePicker';
import WeeklyDatePicker from './DailyLayout/DatePicker/WeeklyDatePicker/WeeklyDatePicker';
import EmptyState from './EmptyState/EmptyState';
import { classes, st } from './Widget.st.css';
import { WidgetLoader } from './WidgetLoader/WidgetLoader';
import { Spinner } from 'wix-ui-tpa/cssVars';
import { isLayoutWithTimeSlot } from '../../../utils/layouts';

export interface ControllerProps extends CalendarActions, CalendarViewModel {}

const WidgetWrapper: React.FC<WidgetProps<ControllerProps>> = (props) => {
  const { widgetViewModel } = props;

  return widgetViewModel?.emptyStateViewModel ? (
    <>
      <BackButton viewModel={widgetViewModel.backButtonViewModel} />
      <EmptyState {...widgetViewModel?.emptyStateViewModel} />
    </>
  ) : (
    <CalendarFlowProvider value={widgetViewModel.focusedElement}>
      <PresetProvider value={widgetViewModel.preset}>
        <CalendarActionsProvider value={props}>
          <Widget {...props} />
        </CalendarActionsProvider>
      </PresetProvider>
    </CalendarFlowProvider>
  );
};

const Widget: React.FC<WidgetProps<ControllerProps>> = (props) => {
  const {
    headerViewModel,
    rescheduleDetailsViewModel,
    widgetViewModel: { backButtonViewModel, status },
    initializeWidget,
  } = props;
  const accessibilityAttributes = useWidgetAccessibilityAttributes(
    headerViewModel?.title,
  );
  const { experiments } = useExperiments();
  const { preset } = usePreset();
  const settings = useSettings();
  const settingsParams = useSettingsParams();
  const { isMobile, isRTL } = useEnvironment();

  useEffect(() => {
    if (status === CalendarStatus.INITIALIZING) {
      initializeWidget();
    }
  }, [initializeWidget, status]);

  const getWidgetLoader = () =>
    experiments.enabled('specs.bookings.calendar.clsLoaderImprovements') ? (
      <WidgetLoader
        withDatePickerPlaceholder={
          !isLayoutWithTimeSlot(settings, settingsParams)
        }
      />
    ) : (
      <div
        data-hook="widget-loader-deprecated"
        className={classes.loaderWrapper}
      >
        <Spinner diameter={50} className={classes.loader} />
      </div>
    );

  const getWidgetContentByStatus = () => {
    switch (status) {
      case CalendarStatus.INITIALIZING:
        return getWidgetLoader();
      case CalendarStatus.IDLE:
      case CalendarStatus.NAVIGATING:
        return <BottomSectionContent {...props} />;
      default:
        return getWidgetLoader();
    }
  };

  return (
    <div
      className={st(classes.root, {
        isRTL,
        isMobile,
        textAlignment: settings.get(settingsParams.textAlignment),
        columnAlignment: settings.get(settingsParams.columnAlignment),
        layout: settings.get(settingsParams.calendarLayout),
        showTopSection: !!headerViewModel || !!rescheduleDetailsViewModel,
        isNavigating: status === CalendarStatus.NAVIGATING,
        preset,
      })}
      dir={isRTL ? 'rtl' : 'ltr'}
      data-hook="BookingCalendar-wrapper"
      {...accessibilityAttributes}
    >
      <div data-hook="top-section" className={classes.topSection}>
        <BackButton viewModel={backButtonViewModel} />
        <div className={classes.topSectionHeader}>
          <div
            data-hook="top-section-content"
            className={classes.topSectionContentContainer}
          >
            {rescheduleDetailsViewModel && (
              <RescheduledBookingDetails
                viewModel={rescheduleDetailsViewModel}
              />
            )}
            {headerViewModel ? <Header viewModel={headerViewModel!} /> : null}
          </div>
        </div>
      </div>
      <div data-hook="bottom-section" className={classes.bottomSection}>
        <div
          data-hook="bottom-section-content"
          className={classes.bottomSectionContentContainer}
        >
          {getWidgetContentByStatus()}
        </div>
      </div>
      <Dialog viewModel={props.dialogViewModel!} />
      <Toast viewModel={props.toastViewModel!} />
    </div>
  );
};

const BottomSectionContent: React.FC<ControllerProps> = (props) => {
  const bottomSectionRef = useFlow(FlowElements.BOTTOM_SECTION);
  const { sidebarViewModel } = props;

  return (
    <div className={classes.bottomSectionContent} ref={bottomSectionRef}>
      <Body {...props} />
      {sidebarViewModel ? <Sidebar viewModel={sidebarViewModel} /> : null}
    </div>
  );
};

const Body: React.FC<ControllerProps> = ({
  widgetViewModel: { errorNotificationText },
  bodyViewModel,
}) => {
  const {
    weeklyTimeSlotsLayoutViewModel,
    dailyTimeSlotsMonthlyPickerLayoutViewModel,
    dailyTimeSlotsWeeklyPickerLayoutViewModel,
    weeklyTimetableLayoutViewModel,
    dailyAgendaWeeklyPickerLayoutViewModel,
  } = bodyViewModel!;

  return (
    <div className={classes.body} data-hook="body">
      {dailyTimeSlotsMonthlyPickerLayoutViewModel ? (
        <DailyTimeSlotsLayout
          viewModel={dailyTimeSlotsMonthlyPickerLayoutViewModel}
          errorNotificationText={errorNotificationText}
          datePickerComponent={
            <MonthlyDatePicker
              viewModel={
                dailyTimeSlotsMonthlyPickerLayoutViewModel.datePickerViewModel
              }
            />
          }
        />
      ) : null}
      {dailyTimeSlotsWeeklyPickerLayoutViewModel ? (
        <DailyTimeSlotsLayout
          viewModel={dailyTimeSlotsWeeklyPickerLayoutViewModel}
          errorNotificationText={errorNotificationText}
          datePickerComponent={
            <WeeklyDatePicker
              viewModel={
                dailyTimeSlotsWeeklyPickerLayoutViewModel.datePickerViewModel
              }
            />
          }
        />
      ) : null}
      {weeklyTimeSlotsLayoutViewModel ? (
        <WeeklyTimeSlotsLayout
          viewModel={weeklyTimeSlotsLayoutViewModel}
          errorNotificationText={errorNotificationText}
        />
      ) : null}
      {weeklyTimetableLayoutViewModel ? (
        <WeeklyTimetableLayout
          viewModel={weeklyTimetableLayoutViewModel}
          errorNotificationText={errorNotificationText}
        />
      ) : null}
      {dailyAgendaWeeklyPickerLayoutViewModel ? (
        <DailyAgendaWeeklyPickerLayout
          viewModel={dailyAgendaWeeklyPickerLayoutViewModel}
          errorNotificationText={errorNotificationText}
        />
      ) : null}
    </div>
  );
};

export default WidgetWrapper;
